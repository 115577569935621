/*-----------------------------------------------
|   Scrollbar style for windows
-----------------------------------------------*/
.scrollbar{
  overflow: auto;
}
.windows, .osx {
  &.chrome{
    .falcon-scrollbar{
      @include chrome-scrollbar;
    }
    .navbar-vibrant{
     .falcon-scrollbar{
        @include chrome-scrollbar(rgba($white, 0.4));
      } 
    }
  }
  &.safari{
    .falcon-scrollbar{
      @include chrome-scrollbar;
    }
    .navbar-vibrant{
     .falcon-scrollbar{
        @include chrome-scrollbar(rgba($white, 0.4));
      } 
    }
  }
  &.firefox{
    .falcon-scrollbar{
      @include firefox-scrollbar;
    }
    .navbar-vibrant{
     .falcon-scrollbar{
        @include firefox-scrollbar(rgba($white, 0.4));
      } 
    }
  }
}

.simplebar-content-wrapper {
  overflow: auto;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}
.simplebar-scrollbar::before {
  background-color: $simplebar-bg;
  width: 5px;
  border-radius: 3px;
  right: 0;
  left: auto;
}