/*-----------------------------------------------
|   Form
-----------------------------------------------*/
label {
  font-size: map_get($font-sizes, "-1");
  font-weight: $font-weight-bold;
  letter-spacing: 0.02em;
}

.form-check-label {
  line-height: 1.45rem;
}

/*-----------------------------------------------
|   Remove spinner from Input[type="number"]
-----------------------------------------------*/
.input-spin-none::-webkit-outer-spin-button,
.input-spin-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-spin-none[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
