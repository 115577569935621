/*-----------------------------------------------
|   Table
-----------------------------------------------*/
.table{
  tfoot > tr > th:first-child,
  thead > tr > th:first-child,
  tr th:first-child,
  tr td:first-child{ padding-left: $card-spacer-x; }
  tfoot > tr > th:last-child,
  thead > tr > th:last-child,
  tr th:last-child,
  tr td:last-child{ padding-right: $card-spacer-x; }
}
.white-space-nowrap{
  width: 1px;
  white-space: nowrap;
}
th{ font-weight: $font-weight-semi-bold; }
td .dropdown-toggle:after{ display: none; }
.table-dashboard{
  overflow: hidden;
  th{ border-bottom-width: 1px !important; }
}

// Falcon 2.7
/*.table{
  tfoot > tr > th:first-child,
  thead > tr > th:first-child,
  tr th:first-child,
  tr td:first-child{ padding-left: $card-spacer-x; }
}
.white-space-nowrap{
  width: 1px;
  white-space: nowrap;
}
th{ font-weight: $font-weight-semi-bold; }
td .dropdown-toggle:after{ display: none; }
.table-dashboard{
  overflow: hidden;
  th{ border-bottom-width: 1px !important; }
}*/

// .table {
//   --#{$variable-prefix}table-bg: #{$table-bg};
//   --#{$variable-prefix}table-accent-bg: transparent;
//   --#{$variable-prefix}table-striped-color: #{$table-striped-color};
//   --#{$variable-prefix}table-striped-bg: #{map-get($grays, '100')};
//   --#{$variable-prefix}table-active-color: #{$table-active-color};
//   --#{$variable-prefix}table-active-bg: #{$table-active-bg};
//   --#{$variable-prefix}table-hover-color: #{$table-hover-color};
//   --#{$variable-prefix}table-hover-bg: #{$table-hover-bg};
// }

// .dark .table {
//   --#{$variable-prefix}table-bg: #{$table-bg};
//   --#{$variable-prefix}table-accent-bg: transparent;
//   --#{$variable-prefix}table-striped-color: #{$table-striped-color};
//   --#{$variable-prefix}table-striped-bg: #{map-get($dark-grays, '100')};
//   --#{$variable-prefix}table-active-color: #{$table-active-color};
//   --#{$variable-prefix}table-active-bg: #{$table-active-bg};
//   --#{$variable-prefix}table-hover-color: #{$table-hover-color};
//   --#{$variable-prefix}table-hover-bg: #{$table-hover-bg};
// }